import RestResource from '../rest.service';

export default class ModulesService extends RestResource {
  map(payload) {
    return {
      description: payload.description,
      coverage_type: payload.coverageType,
      sort_order: payload.sortOrder,
      sancor_id: payload.sancorId,
      harvest: payload.harvest,
      allow_multiple_risks: payload.allowMultipleRisks,
      video: payload.video,
      pdf_description: payload.pdfDescription,
      campaign_id: payload.campaignId,
      crop_description: payload.cropDescription,
      company_id: payload.companyId
    };
  }

  getAllModules() {
    return this.get(`${this.prefix}/modules-all`);
  }

  getModuleRatesPag(module, params = {}) {
    return this.get(`${this.route}/module-rates-pag/${module}`, { params });
  }

  getModuleRates(module) {
    return this.get(`${this.route}/module-rates/${module}`);
  }

  constructor(api) {
    super(api, 'modules', 'api');
  }
}
