<template>
  <div class="wrapper wrapper-full-page login-page">
    <div class="content">
      <div class="container">
        <img class="login_logo" src="@/assets/logo_fernandez.png" />
        <div class="row">
          <div class="col-lg-4 col-md-6 ml-auto mr-auto">
            <form @submit.prevent="login">
              <div class="card">
                <div class="card-header">
                  <h3>Iniciar sesión</h3>
                </div>
                <div class="card-content">
                  <fg-input
                    type="text"
                    label="Usuario"
                    :disabled="false"
                    placeholder="Nombre de usuario"
                    v-model="userdata.username"
                  />
                  <fg-input
                    type="password"
                    label="Clave"
                    :disabled="false"
                    placeholder="Clave"
                    v-model="userdata.password"
                  />
                  <p-button type="primary" native-type="submit" :loading="isLoading">Ingresar</p-button>
                </div>
              </div>
            </form>
            <p>
              ¿Olvidaste tu clave?
              <router-link :to="{ name: 'RecoverKey' }">¡Hacé click acá!</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <notifications />
  </div>
</template>

<script>
import { isFinancier } from '@/plugins/roles';
export default {
  name: 'Login',
  data() {
    return {
      userdata: {
        username: '',
        password: ''
      },
      context: 'login',
      isLoading: false
    };
  },
  methods: {
    isFinancier,
    login() {
      this.isLoading = true;
      this.$auth
        .login({
          data: this.userdata,
          rememberMe: false,
          // redirect: {name: redirect ? redirect.from.name : 'account'},
          redirect: '',
          fetchUser: true,
          success: function () {
            if (this.$auth.watch.data.deleted_at) {
              this.$auth.logout({
                makeRequest: false,
                success() {
                  this.isLoading = false;
                  this.$router.push({ name: this.context });
                  this.$notify({
                    message: 'Usuario Eliminado',
                    icon: 'ti-user',
                    horizontalAlign: 'right',
                    verticalAlign: 'bottom',
                    type: 'danger'
                  });
                }
              });
            }
            let redirect = null;
            if (this.$route.query.redirect) {
              redirect = this.$route.query.redirect;
            }
            if (redirect) {
              let route = this.$router.resolve({ path: redirect });
              if (route.resolved.name) {
                this.$router.push({ path: route.resolved.path }).catch(() => {});
              } else {
                this.userProfileRedirect();
              }
            } else {
              this.userProfileRedirect();
            }
          }
        })
        .then(
          res => {
            // console.log('success ' + this.context);
          },
          res => {
            // console.log('error ' + this.context);
            this.$notify({
              message: 'Credenciales incorrectas.',
              icon: 'ti-gift',
              horizontalAlign: 'right',
              verticalAlign: 'bottom',
              type: 'danger'
            });
            this.error = res.data;
            this.isLoading = false;
          }
        );
    },
    userProfileRedirect() {
      if (this.$auth.watch.data.preferred_url) {
        const redirectTo = this.$auth.watch.data.preferred_url;
        this.$router.push({ name: redirectTo });
      } else if (isFinancier(this.$auth.watch.data)) {
        this.$router.push({ name: 'financiacion' });
      } else {
        this.$router.push({ name: 'solicitudes' });
      }
    }
  }
};
</script>

<style>
.login-page {
  background-color: #f4f3ef !important;
}

.login-page > .content {
  padding-top: 5vh;
}

.card .card-header {
  padding: 20px 15px 0;
  position: relative;
  border-radius: 3px 3px 0 0;
  z-index: 3;
}

.card .card-content {
  padding: 15px 15px 10px;
}

.card .card-footer {
  padding: 0 15px 15px;
}

.login_logo {
  display: block;
  width: 300px;
  margin: 0 auto;
}
</style>
