import { nextTick } from 'vue';

export const CROPS = {
  SOJA: 'soy',
  MAIZ: 'corn',
  GIRASOL: 'sunflower',
  TRIGO: 'wheat',
  CEBADA: 'barley',
  CENTENO: 'rye',
  SORGO: 'sorghum',
  AVENA: 'oatmeal'
};

export const CROPS_MAPPING = {
  Soja: CROPS.SOJA,
  Maíz: CROPS.MAIZ,
  Girasol: CROPS.GIRASOL,
  Trigo: CROPS.TRIGO,
  Cebada: CROPS.CEBADA,
  Centeno: CROPS.CENTENO,
  Sorgo: CROPS.SORGO,
  Avena: CROPS.AVENA
};

export const CROPS_RECHARGES = {
  Soja: 'soyRate',
  Maíz: 'cornRate',
  Girasol: 'sunflowerRate',
  Trigo: 'wheatRate'
};

export const CROPS_QUOTATIONS = {
  Soja: 'soyQuotation',
  Maíz: 'cornQuotation',
  Girasol: 'sunflowerQuotation',
  Trigo: 'wheatQuotation'
};

export const CROP_IDS = {
  soy: 1,
  corn: 2,
  wheat: 4
};

export const CROP_NAMES = {
  1: 'Soja',
  2: 'Maiz',
  4: 'Trigo'
};

export const negotiationStatus = {
  ACCEPTED: 'aceptada',
  REJECTED: 'rechazada',
  NEW: 'iniciada',
  UPDATED: 'recotizada',
  REQUESTED: 'solicitud'
};

export const COMPANIES = {
  SANCOR: 1,
  SAN_CRISTOBAL: 2
};

export const ROLES = {
  SUDO: 1,
  ADMIN: 2,
  SUPERVISOR: 3,
  PRODUCER: 4,
  FINANCIER: 5
};

export const scrollToTop = className => {
  nextTick(() => {
    const invalid = document.querySelector(className);
    invalid.scrollIntoView({ behavior: 'smooth', block: 'center' });
  });
};
