<template>
  <card class="d-flex p-3">
    <div class="row justify-content-between p-4">
      <div class="d-flex pr-4">
        <label class="font-weight-bold text-dark">Capital ($)</label>
        <fg-input
          type="number"
          class="tw-min-h-10 tw-max-w-40 tw-px-1"
          placeholder="$ 1.000.000"
          v-model.trim="$v.capital.$model"
          :is-valid="!$v.capital.$error"
          :errorMessage="'Capital inválido'"
        ></fg-input>
      </div>
      <div class="col">
        <p class="font-weight-bold">Capital (USD)</p>
        <p class="info">
          {{ numberFormatter(calculatedData[0].capitalUsd) }}
        </p>
      </div>
      <div class="col">
        <p class="font-weight-bold">Cotización del día dólar ($)</p>
        <p class="info">
          {{ numberFormatter(dollarToday) }}
        </p>
      </div>
      <div class="col">
        <p class="font-weight-bold">Amortización capital</p>
        <p class="info">Al vencimiento</p>
      </div>
      <div class="col">
        <p class="font-weight-bold">Intereses</p>
        <p class="info">Al vencimiento</p>
      </div>
      <div class="col">
        <p class="font-weight-bold">Tipo de crédito</p>
        <p class="info">Bullet</p>
      </div>
      <div v-if="isAdmin" class="d-inline-block justify-content-end button-container">
        <button class="btn btn-success" @click="addRow">Agregar fila</button>
      </div>
    </div>
    <summary-data
      :key="tableKey"
      :showTable="showTable"
      :data="calculatedData"
      :isAdmin="isAdmin"
      @simulate="simulate"
      @remove="removeRow"
      @error="errorToast"
    />
  </card>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import SummaryData from '@/components/RefinancingUsd/SummaryData';
import { isAdmin, isSuperUser } from '@/plugins/roles.js';
import { numberFormatter } from '@/utils/formatters';

export default {
  components: {
    SummaryData
  },

  data() {
    return {
      capital: null,
      tableKey: 0,
      showTable: false,
      dollarToday: 0,
      calculatedData: [
        {
          id: null,
          expirationDate: '',
          tnaPesos: '',
          implicitRate: '',
          tea: '',
          expirationInterest: '',
          futureDollar: '',
          interestCapitalTotal: '',
          effectiveRate: '',
          equivalentTna: ''
        }
      ]
    };
  },

  validations: {
    capital: {
      required,
      numeric
    }
  },

  computed: {
    isAdmin() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    }
  },

  mounted() {
    this.showTable = this.isAdmin;
    this.$api.refinance.getRefinanceParameters().then(response => {
      this.calculatedData = response.data.map(item => {
        return {
          ...item,
          implicitRate: '',
          tea: '',
          expirationInterest: '',
          futureDollar: '',
          interestCapitalTotal: '',
          effectiveRate: '',
          equivalentTna: '',
          capitalUsd: ''
        };
      });
      this.tableKey++;
    });
    this.fetchDollar();
  },

  methods: {
    successToast(message) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        type: 'success'
      });

      Toast.fire({
        title: message
      });
    },

    errorToast(message) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        type: 'error'
      });

      Toast.fire({
        title: message
      });
    },

    async simulate(data) {
      if (this.validate()) {
        try {
          const updatedData = await Promise.all(
            data.map(async row => {
              const payload = {
                capital: this.capital,
                expiration: row.expirationDate,
                tna: row.tnaPesos
              };
              const response = await this.$api.refinance.getRefinanceUsd(payload);
              return response.data;
            })
          );
          this.showTable = true;
          this.calculatedData = updatedData;
          this.tableKey++;
        } catch (error) {
          this.errorToast(error.response.data.message);
        }
      } else {
        this.errorToast('Revise el capital ingresado.');
      }
    },

    async removeRow(row) {
      if (row.id) {
        try {
          await this.$api.refinance.deleteParameter(row.id);
        } catch (error) {
          this.errorToast(error.message);
          return;
        }
      }
      this.calculatedData = this.calculatedData.filter(data => data !== row);
      this.tableKey++;
    },

    async fetchDollar() {
      try {
        const response = await this.$api.simulator.getLastParameters();
        this.dollarToday = response.data.dollars;
      } catch (error) {
        this.errorToast(error.response.data.message);
      }
    },

    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    addRow() {
      if (this.calculatedData.length >= 12) {
        this.errorToast('No se pueden agregar más de 12 filas.');
        return;
      }
      this.calculatedData.push({
        id: null,
        expirationDate: '',
        tnaPesos: '',
        implicitRate: '',
        tea: '',
        expirationInterest: '',
        futureDollar: '',
        interestCapitalTotal: '',
        effectiveRate: '',
        equivalentTna: '',
        capitalUsd: ''
      });
    },
    numberFormatter
  }
};
</script>

<style scoped>
.container {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button-container .btn {
  margin: 10px;
}

span {
  font-size: 16px;
}

::v-deep .el-checkbox__inner {
  background: #e8e8e8;
  border-color: rgb(148, 148, 148);
}

@media (max-width: 600px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }

  .button-container .btn {
    width: 100%;
    margin: 10px 0;
  }
}
</style>
