import NegotiationsService from './services/negotiations.js';
import QuotationsService from './services/quotations.js';
import RisksService from './services/risks.js';
import LocationsService from './services/locations.js';
import reports from './services/reports.js';
import StatisticsService from './services/statistics.js';
import ContactService from './services/contact.js';
import CampaignsService from './services/campaigns.js';
import ClientsService from './services/clients.js';
import UsersService from './services/users.js';
import ProvincesService from './services/provinces';
import ModulesService from './services/modules.js';
import SettingsService from './services/settings.js';
import ZonesService from './services/zones.js';
import RatesService from './services/rates.js';
import IgnoreDiscountsService from './services/ignore_discounts.js';
import CropsService from './services/crops.js';
import TaxVatsService from './services/taxVats.js';
import CoveragesUserService from './services/coveragesUser.js';
import CoveragesService from './services/coverages.js';
import SimpleNegotiationsService from './services/simpleNegotiations.js';
import FinishNegotiationService from './services/finishNegotiation.js';
import SimulatorService from './services/simulator.js';
import ProposalsService from './services/proposals.js';
import RefinanceService from './services/refinance.js';
import RolesService from './services/roles.js';
import NotesService from './services/notes.js';
import CompaniesService from './services/companies.js';

export default api => ({
  users: new UsersService(api),
  clients: new ClientsService(api),
  modules: new ModulesService(api),
  zones: new ZonesService(api),
  crops: new CropsService(api),
  rates: new RatesService(api),
  negotiations: new NegotiationsService(api),
  quotations: new QuotationsService(api),
  campaigns: new CampaignsService(api),
  settings: new SettingsService(api),
  simpleNegotiations: new SimpleNegotiationsService(api),
  risks: new RisksService(api),
  locations: new LocationsService(api),
  reports,
  statistics: new StatisticsService(api),
  coveragesUser: new CoveragesUserService(api),
  contact: new ContactService(api),
  provinces: new ProvincesService(api),
  ignoreDiscounts: new IgnoreDiscountsService(api),
  taxVats: new TaxVatsService(api),
  coverages: new CoveragesService(api),
  finishNegotiation: new FinishNegotiationService(api),
  simulator: new SimulatorService(api),
  proposal: new ProposalsService(api),
  refinance: new RefinanceService(api),
  roles: new RolesService(api),
  notes: new NotesService(api),
  companies: new CompaniesService(api)
});
