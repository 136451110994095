<template>
  <div class="tax_vat">
    <card>
      <div slot="header">
        <h4 class="card-title">Aclaraciones</h4>
        <p class="card-category">Aclaraciones al pie de PDFs.</p>
      </div>

      <!-- editar aclaración -->
      <form v-if="editRow" id="noteForm">
        <h4>Editar Aclaración</h4>
        <div class="form-fields">
          <div class="form-field">
            <div class="d-inline-block py-2">
              <label>Aclaración:</label>
              <editor v-model="value" />
            </div>
          </div>
        </div>
        <div class="button-container">
          <button class="btn btn-primary my-1" @click="saveEditedTax()">
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            Guardar cambios
          </button>
          <button class="btn btn-secondary my-1" @click="cleanEditRow">
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            Cancelar cambios
          </button>
        </div>
      </form>

      <!-- aclaraciones cargadas -->
      <div slot="raw-content" class="table-responsive" v-if="isDataLoaded">
        <entity-table
          :columns="['Id', 'Método de pago', 'Opción de tasa', 'Moneda']"
          type="hover"
          class="table-sm"
          :tableData="notes"
          :total="total"
          @refetch="fetchNotes"
        >
          <template slot-scope="{ row }">
            <td>{{ row.id }}</td>
            <td>{{ getPaymentMethod(row.paymentMethodId).label }}</td>
            <td>{{ getRateOption(row.paymentMethodId, row.rateOptionId) }}</td>
            <td>{{ getHectarePriceCurrencyId(row.hectarePriceCurrencyId) }}</td>
            <td>
              <button class="btn btn-primary btn-xs" @click="showEditNote(row)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </template>
        </entity-table>
      </div>
    </card>
  </div>
</template>

<script>
import EntityTable from '@/components/EntityTable';
import Editor from '@/components/Editor/Editor';
import paymentMethods from '@/plugins/paymentMethods.js';
import constants from '@/plugins/constants.js';

export default {
  name: 'NotesEditor',
  components: {
    EntityTable,
    Editor
  },
  data() {
    return {
      paymentMethods: paymentMethods.METHODS,
      currencies: [
        { code: constants.PESO, label: 'Pesos' },
        { code: constants.DOLLAR, label: 'Dólares' },
        { code: constants.QUINTAL, label: 'Quintales' }
      ],
      notes: [],
      editRow: null,
      isDataLoaded: false,
      total: 0,
      value: ''
    };
  },
  mounted() {
    this.fetchNotes();
  },
  methods: {
    fetchNotes(params = { 'filter[search]': '', perPage: 10, currentPage: 1 }) {
      this.$api.notes.getAll(params).then(response => {
        this.notes = response.data.notes;
        this.total = response.meta.total;
        this.isDataLoaded = true;
      });
    },
    saveEditedTax() {
      this.editRow.value = this.value;
      this.$api.notes.update(this.editRow.id, this.editRow).then(() => {
        this.$swal({
          title: 'Éxito!',
          text: 'Aclaración editada correctamente',
          type: 'success'
        });
      });
      this.value = null;
      this.editRow = null;
    },
    showEditNote(note) {
      this.editRow = note;
      this.value = note.value;
      this.$nextTick(() => {
        const element = document.getElementById('noteForm');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        } else {
          console.warn('Element not found: noteForm');
        }
      });
    },
    cleanEditRow() {
      this.value = null;
      this.editRow = null;
    },
    getPaymentMethod(paymentMethodId) {
      return this.paymentMethods.find(method => method.code === paymentMethodId);
    },
    getRateOption(paymentMethodId, rateOptionId) {
      return this.getPaymentMethod(paymentMethodId).rateOptions.find(option => option.code === rateOptionId).label;
    },
    getHectarePriceCurrencyId(hectarePriceCurrencyId) {
      return this.currencies.find(currency => currency.code === hectarePriceCurrencyId).label;
    }
  }
};
</script>

<style scoped>
.button-container .btn {
  margin: 10px;
}

@media (max-width: 600px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }

  .button-container .btn {
    width: 100%;
    margin: 10px 0;
  }
}
</style>
