export function numberFormatter(value) {
  if (!value) return '0.00';
  const number = new Intl.NumberFormat().format(value);

  return number;
}

export function formatDate(date) {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];

  const newDate = new Date(date);
  const month = months[newDate.getMonth()];
  const year = newDate.getFullYear();

  return `${month} ${year}`;
}
