import RestResource from '../rest.service';
import axios from 'axios';

export default class RolesService extends RestResource {
  getAll() {
    return this.get(`${axios.defaults.baseURL}/api/roles`);
  }

  constructor(api) {
    super(api, 'roles', 'api');
  }
}
