<template>
  <div class="tabla-contenedor">
    <div class="w-100">
      <entity-table
        v-if="showTable"
        :table-data="tableData"
        :total="tableData.length"
        :has-actions="false"
        :has-search="false"
        :has-pagination="false"
      >
        <template #columns>
          <th v-for="column in tableColumns" :key="column.name" :class="[column.class]">
            {{ column.name }}
          </th>
        </template>
        <template slot-scope="{ row, index }">
          <td class="tw-w-36">
            <template v-if="isAdmin">
              <fg-input
                type="date"
                class="table-input"
                :min="minDate"
                :max="maxDate"
                v-model.trim="$v.tableData.$each[index].expirationDate.$model"
                :is-valid="!$v.tableData.$each[index].expirationDate.$error"
                :errorMessage="null"
              ></fg-input>
            </template>
            <template v-else>
              {{ formatDate(row.expirationDate) }}
            </template>
          </td>
          <td class="tw-w-28">
            <template v-if="isAdmin">
              <fg-input
                type="number"
                class="table-input"
                v-model.trim="$v.tableData.$each[index].tnaPesos.$model"
                :is-valid="!$v.tableData.$each[index].tnaPesos.$error"
                :errorMessage="null"
                placeholder="50%"
              ></fg-input>
            </template>
            <template v-else>{{ row.tnaPesos }}%</template>
          </td>
          <td>{{ numberFormatter(row.implicitRate) }}%</td>
          <td>{{ numberFormatter(row.tea) }}%</td>
          <td>{{ row.daysToExpiration }}</td>
          <td>{{ numberFormatter(row.expirationInterest) }}%</td>
          <td>{{ numberFormatter(row.futureDollar) }}</td>
          <td>{{ numberFormatter(row.interestCapitalTotal) }}</td>
          <td
            :class="{
              positive: row.effectiveRate < 0,
              negative: row.effectiveRate > 0
            }"
          >
            {{ numberFormatter(row.effectiveRate) }}%
          </td>
          <td
            :class="{
              positive: row.equivalentTna < 0,
              negative: row.equivalentTna > 0
            }"
          >
            {{ numberFormatter(row.equivalentTna) }}%
          </td>
          <td v-if="isAdmin">
            <div class="justify-content-center text-center">
              <button
                class="tw-bg-red-500 tw-uppercase tw-text-xs tw-hover:bg-red-700 tw-text-white tw-font-bold tw-rounded tw-px-2 tw-py-1"
                @click="$emit('remove', row)"
              >
                Eliminar
              </button>
            </div>
          </td>
        </template>
      </entity-table>
      <div class="d-flex justify-content-end button-container">
        <button class="btn btn-info" @click="simulate()">Aceptar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import EntityTable from '@/components/EntityTable';
import { numberFormatter, formatDate } from '@/utils/formatters';

export default {
  components: {
    EntityTable
  },

  props: {
    data: {
      type: Array
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    showTable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tableData: this.data,
      tableColumns: [
        { name: 'Vencimiento' },
        { name: 'T.N.A. (Pesos)' },
        { name: 'T.N.A' },
        { name: 'T.E.A.' },
        { name: 'Días transcurridos' },
        { name: 'Interés al Vto.' },
        { name: 'Dólar al Vto.' },
        { name: 'Capital + Interés al Vto. (U$D)' },
        { name: 'Tasa Efectiva al Vto.' },
        { name: 'T.N.A en dólares equivalente' },
        { name: 'Acciones', class: { 'd-none': !this.isAdmin } }
      ]
    };
  },

  computed: {
    minDate() {
      const date = new Date();
      return date.toISOString().split('T')[0];
    },
    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      date.setMonth(date.getMonth(), 1);
      date.setDate(0);
      return date.toISOString().split('T')[0];
    }
  },

  validations() {
    return {
      tableData: {
        $each: {
          expirationDate: {
            required,
            minDate: value => value >= this.minDate,
            maxDate: value => value <= this.maxDate
          },
          tnaPesos: {
            required,
            numeric,
            notZeroOrEmpty: value => value !== null && value >= 0
          }
        }
      }
    };
  },

  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    simulate() {
      if (this.validate()) {
        this.$emit('simulate', this.tableData);
      } else {
        this.$emit('error', 'Las fechas de vencimiento y los TNA deben ser completados correctamente.');
      }
    },
    formatDate,
    numberFormatter
  }
};
</script>

<style scoped>
/* Tablas */
.tabla-contenedor {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.table-input {
  padding-top: 1rem;
  padding-bottom: 0rem;
}
</style>
