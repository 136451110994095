<template>
  <div class="row">
    <div class="col-12">
      <refinance-dollars />
    </div>
  </div>
</template>
<script>
import RefinanceDollars from './Refinance/RefinancingDollars';
export default {
  components: {
    RefinanceDollars
  }
};
</script>
<style></style>
