<template>
  <div class="row">
    <div class="col-lg-12">
      <card>
        <div slot="raw-content" v-if="isDataLoaded">
          <form-wizard
            title="Cotizar varios riesgos"
            subtitle="Completa el asistente para poder generar la cotización al cliente."
            back-button-text="Atras"
            next-button-text="Siguiente"
            finish-button-text="Finalizar"
            color="#41B883"
            error-color="rgb(221, 51, 51)"
            shape="tab"
            @on-change="changeStep"
          >
            <tab-content title="Cliente" icon="ti-user" :before-change="validateClientData">
              <div class="row">
                <div class="col-xl"></div>
                <div class="col-xl-8 col-md-12">
                  <producer-form
                    v-if="canSelectProducer"
                    :producer="negotiation.producerId"
                    :simple-negotiation="simpleNegotiation"
                    :update-negotiation="updateNegotiation"
                    @onProducerChange="setProducer"
                  />
                  <client-form
                    :client-id="negotiation.clientId"
                    :producer-id="negotiation.producerId"
                    :simple-negotiation="simpleNegotiation"
                    :update-negotiation="updateNegotiation"
                    :is-simple-negotiation="simpleNegotiation !== null"
                    :is-update-negotiation="updateNegotiation !== null"
                    @change="setClient"
                    ref="clientForm"
                  />
                  <div v-if="existObservation">
                    <div class="form-group">
                      <label>Observaciones</label>
                      <textarea
                        class="form-control custom-text-area"
                        v-model="negotiation.observations"
                        :disabled="simpleNegotiation !== null"
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <h4 class="d-inline-block">Riesgos</h4>
                      <div class="form-group">
                        <label>Unidad</label>
                        <select
                          cy-id="currency-select"
                          class="custom-select"
                          v-model="currency"
                          :disabled="simpleNegotiation !== null"
                        >
                          <option value="">Seleccione</option>
                          <option :value="constants.PESO">$</option>
                          <option :value="constants.QUINTAL">QQ</option>
                          <option :value="constants.DOLLAR">USD</option>
                        </select>
                      </div>
                    </div>
                    <div v-if="simpleNegotiation !== null">
                      <risk-item
                        v-for="risk in negotiation.risks"
                        :key="risk.id"
                        :risk="risk"
                        :disabled="true"
                        :start-open="true"
                        :currency="currency"
                        :is-simple-negotiation="true"
                        @addRisk="addRisk"
                        @remove="removeRisk"
                        ref="riskItemsSimpleNegotiation"
                      />
                    </div>
                    <div v-if="updateNegotiation !== null">
                      <risk-item
                        v-for="risk in negotiation.risks"
                        :key="risk.id"
                        :risk="risk"
                        :start-open="true"
                        :disabled="true"
                        :currency="currency"
                        :is-update-negotiation="true"
                        @addRisk="addRisk"
                        @remove="removeRisk"
                        ref="riskItemsUpdateNegotiation"
                      />
                    </div>

                    <div v-else>
                      <risk-item
                        v-for="risk in negotiation.risks"
                        :key="risk.id"
                        :start-open="!risk.negotiationId"
                        :risk="risk.negotiationId ? risk : null"
                        :currency="currency"
                        :is-simple-negotiation="false"
                        :disabled="true"
                        @addRisk="addRisk"
                        @remove="removeRisk"
                        ref="riskItems"
                      />
                    </div>
                    <div v-if="simpleNegotiation === null" class="d-flex justify-content-center">
                      <button class="button-add" @click="addRiskForm" cy-id="add-risk">
                        <span class="ti-plus" />
                        Agregar
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xl" />
              </div>
            </tab-content>

            <tab-content title="Coberturas" icon="ti-view-list-alt" :before-change="validateCoverages">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <coverages-form
                    v-if="isDataLoaded"
                    :negotiation-group="true"
                    :risks="cropRisks"
                    :modules="updateNegotiation ? updateNegotiation.modules : []"
                    :harvest="mainHarvest"
                    :show-double-crop="isDoubleCrop"
                    :show-split-coverage="canHaveSplitCoverage"
                    :user-favorites="producerFavoriteCoverages"
                    :quotationUserId="negotiation.producerId"
                    @changeCoverages="setCoverages"
                    @cambioFiltroFD="setFiltroFD"
                    @coveragesToSplitChange="coverageToSplitChange"
                  />
                </div>
                <div class="col-xl" />
              </div>
            </tab-content>

            <tab-content title="Configuraciones" icon="ti-settings" :before-change="validateForm">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <settings-form
                    :currency="currency"
                    :configuraciones="negotiation.configurations"
                    @cambioConfiguraciones="setConfiguraciones"
                  />
                  <discount-form v-if="currentStep == 2" :risks="negotiation.risks" @changeDiscount="setDiscounts" />
                </div>
                <div class="col-xl" />
              </div>
            </tab-content>

            <tab-content title="Terminar" icon="ti-check">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <preview v-if="negotiationPreview !== null" :negotiation="negotiationPreview" />
                </div>
                <div class="col-xl" />
              </div>
            </tab-content>

            <template slot="footer" slot-scope="props">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <div class="wizard-footer-left">
                    <wizard-button
                      v-if="props.activeTabIndex > 0"
                      @click.native="props.prevTab()"
                      :style="estiloBotonVolver"
                    >
                      <i class="ti-angle-left" />
                      Volver
                    </wizard-button>
                  </div>
                  <div class="wizard-footer-right">
                    <wizard-button
                      v-if="!props.isLastStep"
                      cy-id="next"
                      @click.native="props.nextTab()"
                      class="wizard-footer-right"
                      :style="props.fillButtonStyle"
                    >
                      Siguiente
                      <i class="ti-angle-right" />
                    </wizard-button>

                    <wizard-button
                      v-else
                      cy-id="final"
                      @click.native="saveChanges"
                      class="wizard-footer-right finish-button"
                      :style="props.fillButtonStyle"
                    >
                      {{ props.isLastStep ? 'Finalizar' : 'Siguiente' }}
                    </wizard-button>
                  </div>
                </div>
                <div class="col-xl" />
              </div>
            </template>
          </form-wizard>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import constants from '@/plugins/constants.js';
import paymentMethods from '@/plugins/paymentMethods.js';
import { isAdmin, isSuperUser, isSupervisor } from '@/plugins/roles.js';
import RiskItem from '@/components/Negotiation/Group/RiskItem.vue';
import ProducerForm from '@/components/Negotiation/Forms/ProducerForm';
import ClientForm from '@/components/Negotiation/Forms/ClientForm';
import { generateEmptyRisk } from '@/utils/risk';
import { scrollToTop } from '@/utils/general';
import { mapActions, mapGetters } from 'vuex';
import DiscountForm from '@/components/Negotiation/Forms/DiscountForm';
import CoveragesForm from '@/components/Negotiation/Forms/CoveragesForm.vue';
import SettingsForm from '@/components/Negotiation/Forms/SettingsForm.vue';
import Preview from '@/components/Negotiation/Forms/Preview.vue';

export default {
  name: 'Grupo',
  components: {
    FormWizard,
    WizardButton,
    TabContent,
    ProducerForm,
    ClientForm,
    SettingsForm,
    DiscountForm,
    Preview,
    RiskItem,
    CoveragesForm
  },
  data() {
    return {
      cropRisks: [],
      calls: [],
      constants: constants,
      isValid: false,
      currentStep: 0,
      negotiationId: this.$route.params.id,
      client: null,
      negotiation: {
        clientId: 0,
        producerId: this.$auth.user().id,
        type: constants.GROUPED,
        state: constants.INITIATED,
        observations: '',
        risks: [generateEmptyRisk()],
        modules: [],
        configurations: {
          paymentMethod: 0,
          rateOption: 0,
          correctionFactor: 0,
          observations: this.configurations ? this.configurations.observations : ''
        }
      },
      doubleCropRisks: [],
      double: {
        id: 0,
        discount: null
      },
      negotiationPreview: null,
      currency: constants.QUINTAL,
      negociacionPreview: null,
      estiloBotonVolver: {
        backgroundColor: '#fafafa',
        borderColor: '#e0e0e0',
        color: '#999'
      },
      producerFavoriteCoverages: [],
      createdInWeb: '',
      isDataLoaded: false,
      isNegotiationLoaded: false,
      simpleNegotiation: null,
      updateNegotiation: null
    };
  },
  computed: {
    ...mapActions(['fetchCrops']),
    ...mapGetters({ crops: 'getCrops' }),
    canSelectProducer() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user()) || isSupervisor(this.$auth.user());
    },
    isDoubleCrop() {
      return this.negotiation.risks.some(r => r.hasOwnProperty('doubleCrop'));
    },
    hasSimpleCrop() {
      return this.negotiation.risks.some(r => Object.keys(r.doubleCrop).length === 0);
    },
    existObservation() {
      return this.simpleNegotiation !== null && this.negotiation.observations !== '';
    },
    mainHarvest() {
      return this.negotiation.risks[0]?.crop?.harvest ?? '';
    },

    canHaveSplitCoverage() {
      return this.$auth.user().allow_split_coverages === 1;
    },
    isDoubleCropAvailable() {
      return this.negotiation.risks.some(r => r.crop?.allowDoubleCrop === 1);
    }
  },
  mounted() {
    this.validateFetchCrop();
    this.fetchNegotiation();
  },
  methods: {
    validateFetchCrop() {
      if (this.crops.length === 0) {
        this.fetchCrops;
      }
    },
    changeStep(prevIndex, nextIndex) {
      this.currentStep = nextIndex;
    },
    addRisk(risk) {
      let riskIndex = this.negotiation.risks.findIndex(obj => obj.id === risk.id);
      this.negotiation.risks[riskIndex] = risk;
      this.cropRisks = this.negotiation.risks.map(r => r.crop?.description);
    },
    saveChanges() {
      this.negotiationPreview.isConfirmed = constants.CONFIRMED;
      this.negotiationPreview.state = constants.INITIATED;
      this.negotiationPreview.type = constants.GROUPED;
      this.$api.negotiations.update(this.negotiationPreview.id, this.negotiationPreview).then(response => {
        if (this.updateNegotiation) {
          this.$swal({
            title: 'Listo!',
            text: 'Cotización editada correctamente',
            type: 'success'
          });
          this.$router.push({ name: 'ver cotizacion', params: { id: this.negotiationPreview.id } });
        } else {
          this.$swal({
            title: 'Listo!',
            text: 'Cotización creada correctamente',
            type: 'success'
          });
          this.$router.push({ name: 'cotizaciones' });
        }
      });
    },
    validateEachField(prop) {
      const validationResult = prop.map(riskItemRef => {
        return riskItemRef.validate();
      });
      return !validationResult.includes(false);
    },
    validateClientData() {
      let isValid = true;
      let errors = [];

      if (!this.$refs.clientForm.validate()) {
        isValid = false;
      }

      if (this.negotiation.risks.length === 0) {
        isValid = false;
        errors.push('Debe agregar al menos un campo de riesgo.');
      } else if (this.simpleNegotiation) {
        if (!this.validateEachField(this.$refs.riskItemsSimpleNegotiation)) isValid = false;
      } else if (this.updateNegotiation) {
        if (!this.validateEachField(this.$refs.riskItemsUpdateNegotiation)) isValid = false;
      } else {
        if (!this.validateEachField(this.$refs.riskItems)) isValid = false;
      }

      if (isValid) {
        this.canHavePaymentInKind = !this.negotiation.risks.some(r => r.unidadvalorha != 'quintal');
        return true;
      } else {
        scrollToTop('.text-invalid');
        this.handleValidationErrors(errors);
        return false;
      }
    },
    validateCoverages() {
      return new Promise((resolve, reject) => {
        let isValid = true;
        let errors = [];
        if (this.negotiation.modules.length === 0 && !this.isDoubleCrop) {
          errors.push('Debe seleccionar al menos una cobertura.');
          isValid = false;
        }

        if (isValid) {
          resolve(true);
        } else {
          this.handleValidationErrors(errors);
          reject('Error de validacion');
        }
      });
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        // Realizar validaciones, antes de enviar los datos.
        let isValid = true;

        let errors = [];

        if (this.negotiation.configurations.paymentMethod === 0) {
          isValid = false;
          errors.push('Debe seleccionar una forma de pago.');
        }

        if (
          this.negotiation.configurations.rateOption === 0 ||
          this.negotiation.configurations.rateOption === '' ||
          this.negotiation.configurations.rateOption === undefined
        ) {
          isValid = false;
          errors.push('Debe seleccionar una opcion de tasa.');
        }

        if (this.negotiation.risks.some(r => r.discount == null || r.discount === '')) {
          isValid = false;
          errors.push('El descuento no puede estar vacio.');
        }

        if (isValid) {
          if (this.simpleNegotiation) {
            this.finishNegotiation(resolve);
          } else {
            if (this.updateNegotiation) {
              this.saveRisks(resolve);
            } else {
              this.createNegotiation(resolve);
            }
          }
        } else {
          this.handleValidationErrors(errors);
          reject(new Error('Error de validacion'));
        }
      });
    },

    async createNegotiation(resolve) {
      if (this.client) {
        const newClient = await this.$api.clients.create(this.client);
        this.negotiation.clientId = newClient.id;
      }
      if (
        ![paymentMethods.KIND, paymentMethods.FINANCED_KIND].includes(this.negotiation.configurations.paymentMethod)
      ) {
        this.negotiation.configurations.correctionFactor = 0;
      }
      this.$api.simpleNegotiations.create(this.negotiation).then(response => {
        this.negotiationId = response.data.id;
        this.saveRisks(resolve);
      });
    },
    async saveRisks(resolve) {
      let calls = [];
      if (this.updateNegotiation) {
        await this.$api.risks.destroy(this.updateNegotiation.id);
      }
      this.negotiation.risks.forEach((risk, index) => {
        this.$set(this.negotiation.risks[index], 'negotiationId', this.negotiationId);
        if (risk?.doubleCrop?.hectarePrice) {
          risk.isDoubleCrop = true;
          risk.doubleCrop.zoneId = risk.zone.id;
          risk.doubleCrop.cropId = risk.doubleCropId;
        }
        calls.push(
          this.$api.risks.create(risk).then(response => {
            if (risk?.doubleCrop?.hectarePrice) {
              risk.id = response[0].id;
              this.doubleCropRisks.push({
                id: response[1].id,
                discount: risk.discount
              });
              this.negotiation.risks.push({
                id: response[1].id,
                discount: risk.discount
              });
            } else {
              this.negotiation.risks[index].id = response.id;
              this.negotiation.risks[index].discount = response.discount;
            }
            return response;
          })
        );
      });
      Promise.all(calls).then(() => {
        this.finishNegotiation(resolve);
      });
    },
    finishNegotiation(resolve) {
      this.$api.finishNegotiation
        .finishNegotiation(this.negotiationId, this.negotiation)
        .then(response => {
          const doubleCropIds = this.doubleCropRisks.map(dcr => dcr.id);
          this.negotiation.risks = this.negotiation.risks.filter(
            dobleCropRisk => !doubleCropIds.includes(dobleCropRisk.id)
          );
          //this.doubleCropRisks = [];
          this.isPreviewAvailable = true;
          this.negotiationPreview = response.data;
          resolve(true);
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: 'Ocurrió un error mientras estaba generando la vista previa.',
            type: 'error',
            footer: 'ERROR ' + error.response.data.code + ': ' + error.response.data.message
          });
          reject(new Error('Error del servidor'));
        });
    },
    coverageToSplitChange(coveragesToSplit) {
      this.splitCoverages = coveragesToSplit;
    },
    handleValidationErrors(errors) {
      if (errors.length > 0) {
        let errorText = '<p>Ocurrió un error de validación.</p>';
        let errorList = '<ul style="list-style-type:none">';
        errors.forEach(e => {
          errorList += '<li>- ' + e + '</li>';
        });
        errorList += '</ul>';
        this.$swal({
          title: 'Error!',
          html: errorText + errorList,
          type: 'error'
        });
      }
    },
    setProducer(value) {
      this.negotiation.producerId = value.id;
      this.currency = value.hectarePriceCurrency ? value.hectarePriceCurrency : this.currency;
      this.negotiation.configurations.paymentMethod = value.paymentMethod
        ? value.paymentMethod
        : this.negotiation.configurations.paymentMethod;
      this.negotiation.configurations.rateOption = value.rateOption
        ? value.rateOption
        : this.negotiation.configurations.rateOption;
    },
    setClient(value) {
      if (value === null) {
        this.negotiation.clientId = 0;
        this.client = null;
      }
      if (typeof value === 'number') {
        this.negotiation.clientId = value;
      }
      if (typeof value === 'object') {
        this.client = value;
      }
    },
    setCoverages(value) {
      this.negotiation.modules = value;
    },
    setConfiguraciones(value) {
      this.negotiation.configurations = value;
    },
    setDiscounts(value) {
      this.negotiation.risks = value;
    },
    addRiskForm() {
      let risksIds = this.negotiation.risks.map(r => r.id);
      let max = 0;
      if (risksIds.length > 0) max = risksIds.reduce((a, b) => Math.max(a, b));
      this.negotiation.risks.push(generateEmptyRisk(max + 1));
    },
    removeRisk(comp) {
      this.negotiation.risks.splice(
        this.negotiation.risks.findIndex(r => r.id === comp.id),
        1
      );
      this.cropRisks = this.negotiation.risks.map(r => r.crop?.description);
    },
    setFiltroFD(value) {
      this.negotiation.fdFilter = value;
    },
    fetchProducerCoverages(producerId) {
      this.$api.users.getCoverages(producerId).then(response => {
        this.producerFavoriteCoverages = response.data.modules;
      });
    },
    fetchNegotiation() {
      let idNegotiation = this.$route.params.id;
      if (idNegotiation !== undefined) {
        this.$api.negotiations.getOne(idNegotiation).then(response => {
          if (response.data.state === 'solicitud') {
            this.simpleNegotiation = response.data;
            this.negotiation.producerId = this.simpleNegotiation.user.id;
            this.negotiation.clientId = this.simpleNegotiation.client.id;
            this.negotiation.risks = this.simpleNegotiation.risks;
            if (this.simpleNegotiation.observations) {
              this.negotiation.observations = this.simpleNegotiation.observations;
            }
            this.currency = this.simpleNegotiation.risks[0].hectarePriceCurrency.id;
            this.isDataLoaded = true;
          } else {
            this.updateNegotiation = response.data;
            this.setCoverages(this.updateNegotiation.modules);
            this.negotiation.producerId = this.updateNegotiation.user.id;
            this.negotiation.clientId = this.updateNegotiation.client.id;
            this.negotiation.risks = response.data.risks.filter(
              el => (el.isDoubleCrop && el.doubleCropRisk) || !el.isDoubleCrop
            );
            this.currency = this.updateNegotiation.risks[0].hectarePriceCurrency.id;
            this.negotiation.configurations.paymentMethod = this.updateNegotiation.paymentMethodId;
            this.negotiation.configurations.rateOption = this.updateNegotiation.rateOptionId;
            this.negotiation.configurations.correctionFactor = this.updateNegotiation.correctionFactor;
            this.negotiation.observations = this.updateNegotiation.observations;
            this.isDataLoaded = true;
          }
        });
      } else {
        this.isDataLoaded = true;
      }
    }
  }
};
</script>

<style>
.form-group textarea.custom-text-area {
  height: 100px;
}

.button-add {
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  margin-right: auto;
  font-size: 14px;
  margin-left: auto;
  background: #7a9e9f;
  height: 49px;
  border: none;
  color: #fff;
  border-radius: 0.3rem;
  font-weight: bold;
  padding: 11px 30px;
  transition: all;
  transition-duration: 400ms;
}

@media (hover: hover) {
  .button-add:hover {
    background-color: #4d6364;
  }
}

.button-add:active {
  background-color: #2d3a3b;
}
</style>
