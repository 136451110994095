<template>
  <div cy-id="module" class="coverage-container tw-m-3 tw-flex tw-items-center">
    <div
      class="flex-fill coverage-item"
      @click="toggleSelect"
      :class="{
        'tw-bg-gray-200 tw-cursor-not-allowed': isAdditional && disabled,
        selected: coverage.isSelected
      }"
    >
      <div class="coverage-details">
        <p class="coverage-item-name">
          {{ coverage.description }}
        </p>
        <p
          class="tw-inline-block tw-items-center tw-uppercase tw-text-xs tw-font-bold tw-text-white tw-mx-1 tw-px-1.5 tw-rounded tw-bg-[#343A40]"
        >
          {{ coverage.harvest | tipoCosecha }}
          -
          {{ coverage.cropDescription }}
        </p>
        <span
          class="tw-inline-block tw-items-center tw-uppercase tw-text-xs tw-font-bold tw-text-white tw-mx-1 tw-px-1.5 tw-rounded"
          :class="[this.isSanCristobal ? 'badge-san-cristobal ' : 'badge-sancor']"
        >
          {{ this.isSanCristobal ? 'San Cristobal' : 'Sancor' }}
        </span>
      </div>
    </div>
    <div class="for-split">
      <p-switch @click.stop v-if="coverage.isSelected && isSplit" v-model="coverage.isSplit" type="warning" />
    </div>
  </div>
</template>

<script>
import PSwitch from '@/components/Switch.vue';
import { COMPANIES } from '@/utils/general';
export default {
  name: 'CoverageItem',
  components: {
    PSwitch
  },
  props: {
    coverage: {
      type: Object
    },
    isSplit: {
      type: Boolean,
      default: false
    },
    quotationUserId: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isAdditional: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      companies: COMPANIES
    };
  },
  methods: {
    toggleSelect() {
      if (this.disabled) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false,
          type: 'error'
        });

        Toast.fire({
          title: 'Seleccione al menos una cobertura básica de la misma aseguradora'
        });
        return;
      }
      this.coverage.isSelected = !this.coverage.isSelected;
      if (this.coverage.isSelected) {
        this.$emit('select', this.coverage.id);
      } else {
        this.$emit('deselect', this.coverage.id);
      }
    },
    toggleSplit() {
      this.coverage.isSplit = !this.coverage.isSplit;
    }
  },
  computed: {
    userId() {
      return this.$route.params.id ?? this.quotationUserId;
    },
    isSanCristobal() {
      return this.companies.SAN_CRISTOBAL === this.coverage.companyId;
    }
  }
};
</script>

<style lang="scss">
.coverage-container {
  .coverage-item {
    border-radius: 10px;
    border: 1px solid #e4e4e4;
    cursor: pointer;
    font-weight: lighter;
    padding: 10px;

    .coverage-details {
      padding: 0px 10px;

      p {
        margin: 0;
      }
    }

    &:hover {
      border-radius: 10px;
      border: 1px solid #e4e4e4;
      background-color: #e4e4e4;
    }

    &.selected {
      background-color: #41b883;
      border-color: #41b883;

      .coverage-details p {
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

.badge-sancor {
  color: #fff;
  background-color: #a70355 !important;
}

.badge-san-cristobal {
  color: #fff;
  background-color: #181669 !important;
}
</style>
